const { div } = require("prelude-ls");
// const { processSync } = require("remark");
const el = wp.element.createElement;

/**
 * Example of a custom SVG path taken from fontastic
*/
const iconEl = 
    el('svg', { width: 24, height: 24, viewBox:"0 0 402.51 450.31",xmlns:"http://www.w3.org/2000/svg"},
    el('defs',null, el('style',null,"<![CDATA[.cls-1{fill:#fff;}]]>")),
  el('path', { d: "M537.67,472.67,601,399.33c3.45-17.5,34.51-162.93,163.12-218.7,46.66-20.23,90.62-21.92,120.21-20A502.39,502.39,0,0,0,865.67,214c-7.9,27.49-9.55,42.26-19.34,67.33a285.84,285.84,0,0,1-16.66,35.34,179.89,179.89,0,0,0-82-12.67l54.66,38A388.48,388.48,0,0,1,723,385.33a389,389,0,0,1-104,26l-36,50Z",transform:"translate(-481.82 -160.05)" } ),
  el('path', { d: "M637.33,375.33c24.22-40.5,64.27-95.36,127.67-144A502.81,502.81,0,0,1,843.33,182c-29.61,22.43-60.65,47.87-92.33,76.67A1344.16,1344.16,0,0,0,637.33,375.33Z",transform:"translate(-481.82 -160.05)" } ),
  el('path', { d: "M521.67,524.67A26,26,0,0,1,531,511.33c6-4.29,12.23-4.18,22-4,10.53.2,19.18.36,26,6.67a25,25,0,0,1,6.67,10.67c7.67-.86,22.9-1.36,30.66,8,2.76,3.32,4,7.15,6,26,1.25,11.49,2,21.08,2.46,27.91a16.21,16.21,0,0,1-.45,12.62c-3.07,6.22-10.69,7.94-14.07,8.74-22.37,5.31-103,.27-107.95-.05a20.1,20.1,0,0,1-20.5-16.37,272.26,272.26,0,0,1,4.51-38.19c2.82-14.72,5-19.17,8.67-22.66C503.39,522.66,516.43,523.88,521.67,524.67Z",trasform:"translate(-481.82 -160.05)" } ),
  el('path', { d: "M792.33,526.67",transform:"translate(-481.82 -160.05)"  }),
  el('path', { d: "M417.67,560.67",transform:"translate(-481.82 -160.05)" } )

);
wp.blocks.registerBlockType("biographyplugin/add-biography-blocks",{
    title:"Biography",
    icon:iconEl,
    category:"common",
    attributes:{
        profileType:{type:'string'},
        profileUri:{type:'string'}
    },

    edit:function(props){
        props.setAttributes({profileUri:document.querySelector('#profile_uri').value});
        function getProfileType(event){
            props.setAttributes({profileType:event.target.value})
        }
        return (
            <div class="bio-block">
                <select name="profile_type" id="profile_type" onChange={getProfileType}>
                    <option value="">select</option>
                    <option value="networth" selected={props.attributes.profileType == 'networth'}>Networth</option>
                    <option value="basic" selected={props.attributes.profileType == 'basic'}>Basic Information</option>
                    <option value="physical" selected={props.attributes.profileType == 'physical'}>Physical Stats</option>
                    <option value="astrology" selected={props.attributes.profileType == 'astrology'}>Signs</option>
                    <option value="education" selected={props.attributes.profileType == 'education'}>Education Details</option>
                    <option value="family" selected={props.attributes.profileType == 'family'}>Family Details</option>
                    <option value="personal" selected={props.attributes.profileType == 'personal'}>Personal Information</option>
                    <option value="social" selected={props.attributes.profileType == 'social'}>Socail Accounts</option>
                    <option value="awards" selected={props.attributes.profileType == 'awards'}>Awards</option>
                    <option value="summary" selected={props.attributes.profileType == 'summary'}>Summary</option>

                </select>
            </div>   
            
        )
    },
    save:function(props){
        return null;
    },
   
});